import React, { useState } from "react"
import $ from "jquery"
import quote from "../assets/images/quote.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useQuery, useMutation, useApolloClient } from "@apollo/client"
import {
  HOMEPAGE_QUERY,
  HOMEPAGE_NEWSLETTER_QUERY,
  CONSULTATION_BLOCK,
  VALIDATE_NEWSLETTER_SUBSCRIBER,
  NEWSLETTER_SUBSCRIBE_MUTATION,
  GET_ALL_FAVOURITE_ARTICLES_USER,
  UPDATE_FAVOURITE_ARTICLES_USER,
  UPDATE_FAVOURITE_ARTICLES_DATA,
  UPDATE_BOOKMARK_ARTICLES_USER,
  UPDATE_BOOKMARK_ARTICLES_DATA,
  GET_ALL_BOOKMARK_ARTICLES_USER,
} from "../components/graphql"
import moment from "moment"
import ReactMarkdown from "react-markdown"
import parse from "html-react-parser"
import BounceIndicator from "react-activity/lib/Bounce"
import "react-activity/lib/Bounce/Bounce.css"
import { Link } from "gatsby"
import trends_placeholder from "../assets/images/blog/trends_placeholder.jpg"
import default_profile_img from "../assets/images/default-profile.png"
import { openPopupWidget } from "react-calendly"
import { validateEmailID } from "../../utils"
import addToMailchimp from "gatsby-plugin-mailchimp"
import LinesEllipsis from "react-lines-ellipsis"
// import OwlCarousel from 'react-owl-carousel';
import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"
import default_user from "../assets/images/default-user.png"
import Login from "../components/login"
import Loadable from "@loadable/component"
import linkedin_icon from "../assets/images/studydetail/linkedin.png"
import whatsapp_icon from "../assets/images/studydetail/whatsapp.png"
import twitter_icon from "../assets/images/studydetail/twitter.png"
import fb_icon from "../assets/images/studydetail/fb.png"
import unwarp_placeholder from "../assets/images/banner_placeholder_1.jpg"
import unwarp_placeholder_1 from "../assets/images/banner_placeholder.jpg"
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share"
import shareimg from "../assets/images/blog/share.png"
import AppoinmentBooking from "../components/dialog/appoinment"

const OwlCarousel = Loadable(() => import("react-owl-carousel"))

const Home = ({ data }) => {
  const client = useApolloClient()
  const homepageContent = useQuery(HOMEPAGE_QUERY)
  const homepageNewsletterContent = useQuery(HOMEPAGE_NEWSLETTER_QUERY)
  const consultationBlock = useQuery(CONSULTATION_BLOCK)
  const [subscriberMail, setSubscriberEmail] = useState("")
  const [subscribeError, setSubscribeError] = useState("")
  const [subscribeSucess, setSubscribeSucess] = useState("")
  const [userID, setUserID] = useState(undefined)
  const [open, setOpen] = useState(false)
  const [login, setLogin] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [username, setUsername] = useState(undefined)
  const [userFavoriteIDs, setUserFavotiteIDs] = useState([])
  const [userBookmarkIDs, setUserBookmarkIDs] = useState([])
  const [showAppoinment, setshowAppoinment] = useState(false)
  const [updateUserFavourite] = useMutation(UPDATE_FAVOURITE_ARTICLES_USER)
  const [updateArticleFavourite] = useMutation(UPDATE_FAVOURITE_ARTICLES_DATA)
  const [updateUserBookmark] = useMutation(UPDATE_BOOKMARK_ARTICLES_USER)
  const [updateArticleBookmark] = useMutation(UPDATE_BOOKMARK_ARTICLES_DATA)
  const existingSubscriberCheck = useQuery(VALIDATE_NEWSLETTER_SUBSCRIBER, {
    variables: { subscriber: subscriberMail },
  })
  const [showShareOption, setShowShareOption] = useState(false)
  const [phoneError, setphoneError] = useState('');
  const [successMessage,setSuccessMessage] = useState("");
  const [errorMessage,setErrorMessage] = useState("");

  const [createSubscriber] = useMutation(NEWSLETTER_SUBSCRIBE_MUTATION)
  var bannerImage = ""
  if (homepageContent.data) {
    if (homepageContent.data.homePage.bannerImage) {
      bannerImage =
        process.env.BACKEND_URL + homepageContent.data.homePage.bannerImage.url
    }
  }

  console.log("/////", homepageContent)

  const userFavouriteData = useQuery(GET_ALL_FAVOURITE_ARTICLES_USER, {
    variables: { id: userID ? userID : "" },
  })

  const userBookmarkData = useQuery(GET_ALL_BOOKMARK_ARTICLES_USER, {
    variables: { id: userID ? userID : "" },
  })

  if (userFavouriteData.data) {
    if (userFavouriteData.data.user) {
      var favArray = []
      userFavouriteData.data.user.favouriteArticles.map((favContent, index) => {
        favArray.push(favContent.id)
      })
      if (JSON.stringify(favArray) !== JSON.stringify(userFavoriteIDs)) {
        setUserFavotiteIDs(favArray)
      }
    }
  }

  if (userBookmarkData.data) {
    if (userBookmarkData.data.user) {
      var bookArray = []
      userBookmarkData.data.user.bookmarkArticles.map((bookContent, index) => {
        bookArray.push(bookContent.id)
      })
      if (JSON.stringify(bookArray) !== JSON.stringify(userBookmarkIDs)) {
        setUserBookmarkIDs(bookArray)
      }
    }
  }

  React.useEffect(() => {
		if (typeof window !== `undefined`) {
			if (typeof document !== `undefined`) {
			localStorage.setItem('lastRevistedPage',window.location.pathname)
			}
		}
	});

  const favoriteClick = (blogId, blogContent) => {
    console.log("111111111111111111111111111", blogContent)

    if (localStorage.getItem("userId")) {
      client
        .query({
          query: GET_ALL_FAVOURITE_ARTICLES_USER,
          variables: { id: userID },
        })
        .then((res) => {
          if (res.data.user) {
            let articleList = []
            if (res.data.user.favouriteArticles.length > 0) {
              res.data.user.favouriteArticles.map((record) => {
                articleList.push(record.id)
              })
            }
            articleList.push(blogId)
            updateUserFavourite({
              variables: {
                id: userID,
                articleID: articleList,
              },
            }).then((response) => {
              var count = 0
              if (blogContent.favoriteCount) {
                count = blogContent.favoriteCount
              }
              count = count + 1
              updateArticleFavourite({
                variables: {
                  id: blogId,
                  favoriteCount: count,
                },
              })
                .then((response) => {
                  console.log(response)
                })
                .finally((e) => {
                  // setLiked(true)
                })
            })
          }
        })
    } else {
      console.log("logouttttttttttttttttttttttttt")
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("redirect", true)
      localStorage.setItem("lastPage","home")
      return false
    }
  }

  const favoriteUnClick = (blogId, blogContent) => {
    console.log("2222222222222222222222222222222", blogContent)
    client
      .query({
        query: GET_ALL_FAVOURITE_ARTICLES_USER,
        variables: { id: userID },
      })
      .then((res) => {
        if (res.data.user) {
          let articleList = []

          if (res.data.user.favouriteArticles.length > 0) {
            res.data.user.favouriteArticles.map((record) => {
              if (blogId !== record.id) {
                articleList.push(record.id)
              }
            })
          }
          updateUserFavourite({
            variables: {
              id: userID,
              articleID: articleList,
            },
          }).then((response) => {
            console.log("reponse", response.data)
            var count = 0
            if (blogContent.favoriteCount) {
              count = blogContent.favoriteCount
            }
            count = count - 1
            updateArticleFavourite({
              variables: {
                id: blogId,
                favoriteCount: count,
              },
            })
              .then((response) => {
                console.log(response)
              })
              .finally((e) => {
                // setLiked(false)
              })
          })
        }
      })
  }
  const bookmarkClick = (blogId, blogContent) => {
    console.log("clickeddddddddddddd", blogContent)
    if (localStorage.getItem("userId")) {
      console.log("XXXXXXXXXXXXXXXXXXXXXXXXX")
      client
        .query({
          query: GET_ALL_BOOKMARK_ARTICLES_USER,
          variables: { id: userID },
        })
        .then((res) => {
          if (res.data.user) {
            let articleList = []
            if (res.data.user.bookmarkArticles.length > 0) {
              res.data.user.bookmarkArticles.map((record) => {
                articleList.push(record.id)
              })
            }
            articleList.push(blogId)
            updateUserBookmark({
              variables: {
                id: userID,
                articleID: articleList,
              },
            }).then((response) => {
              var count = 0
              if (blogContent.wishlistCount) {
                count = blogContent.wishlistCount
              }
              count = count + 1
              updateArticleBookmark({
                variables: {
                  id: blogId,
                  wishlistCount: count,
                },
              })
                .then((response) => {
                  console.log(response)
                })
                .finally((e) => {
                  // setBookMark(true)
                })
            })
          }
        })
    } else {
      console.log("logouttttttttttttttttttttttttt")
      setOpen(true)
      setLogin(true)
      setSuccessMessage("")
      setErrorMessage("")
      setphoneError("")
      localStorage.setItem("redirect", true)
      localStorage.setItem("lastPage","home")
      return false
    }
  }
  const bookmarkUnClick = (blogId, blogContent) => {
    console.log("unclickeddddddddddddddddddd", blogContent)
    client
      .query({
        query: GET_ALL_BOOKMARK_ARTICLES_USER,
        variables: { id: userID },
      })
      .then((res) => {
        if (res.data.user) {
          let articleList = []

          if (res.data.user.bookmarkArticles.length > 0) {
            res.data.user.bookmarkArticles.map((record) => {
              if (blogId !== record.id) {
                articleList.push(record.id)
              }
            })
          }
          updateUserBookmark({
            variables: {
              id: userID,
              articleID: articleList,
            },
          }).then((response) => {
            console.log("reponse", response.data)
            var count = 0
            if (blogContent.wishlistCount) {
              count = blogContent.wishlistCount
            }
            count = count - 1
            updateArticleBookmark({
              variables: {
                id: blogId,
                wishlistCount: count,
              },
            })
              .then((response) => {
                console.log(response)
              })
              .finally((e) => {
                // setBookMark(false)
              })
          })
        }
      })
  }

  React.useEffect(() => {
    console.log("useEffect called")
    setUsername(localStorage.getItem("userName"))
    setUserID(localStorage.getItem("userId"))
  }, [])
  var consultationImage = consultationBlock.data
    ? process.env.BACKEND_URL +
    consultationBlock.data.consultationBlock.thumbnailImage.url
    : undefined

  var handleFavoriteCheck = (val) => {
    return userFavoriteIDs.some((item) => val === item)
  }
  var handleBookmarkCheck = (val) => {
    return userBookmarkIDs.some((item) => val === item)
  }

  const renderConsultationBlock = () => {
    return (
      <div className="row">
        <div className="col-md-6 text pb-2">
          <div className="title">
            {consultationBlock.data.consultationBlock.title1} <br />
            {consultationBlock.data.consultationBlock.title2}
          </div>
          <p>{consultationBlock.data.consultationBlock.shortDescription}</p>
          {process.env.FILLOUT_ENQUIRY_FORM?
          // <button
          //   className="talk"
          //   // onClick={(e) => setshowAppoinment(true)}
          // >
          <div className="talk">
            <Link to= {`${process.env.FILLOUT_ENQUIRY_FORM}`} className="text-dark" >
            {consultationBlock.data.consultationBlock.buttonName}
            </Link>
          </div>
          // </button>
          :null}
        </div>
        <div className="col-md-6 img">
          <img
            src={consultationImage ? consultationImage : ""}
            alt={consultationBlock.data.consultationBlock.altthumbnailName}
          />
        </div>
      </div>
    )
  }
  React.useEffect(() => {
    {
      /* if ( $(window).width() > 767 ) {
        $(window).scroll(function() {
          var scroll = $(window).scrollTop();
          // if (scroll >= 125) {
          //   $('.home-banner-content .banner-section').addClass('move');
          // } else {
          //   $('.home-banner-content .banner-section').removeClass('move');
          // }
          if ($(".unwrap-slider.alternate").length) {
          var sT = $('.unwrap-slider.alternate').offset().top,
             sH = $('.unwrap-slider.alternate').outerHeight(),
             wH = $(window).height() + 200,
             wS = $(this).scrollTop();
             if (wS > (sT+sH-wH)){
               $('.unwrap-slider.alternate').addClass('alter-section');
             }
             else {
               $('.unwrap-slider.alternate').removeClass('alter-section');
             }
           }
          if ($(".unwrap-slider.our-partner").length) {
          var hT = $('.unwrap-slider.our-partner').offset().top,
             hH = $('.unwrap-slider.our-partner').outerHeight() - 150,
             wH = $(window).height() - 30,
             wS = $(this).scrollTop();
             if (wS > (hT+hH-wH)){
               $('.unwrap-slider.our-partner').addClass('move-section');
             }
             else {
               $('.unwrap-slider.our-partner').removeClass('move-section');
             }
          }
        });
      } */
    }
  }, [])
  const handleSubmit = async () => {
    console.log("subscriberMail", subscriberMail)
    if (validateEmailID(subscriberMail)) {
      try {
        var subscriberLength = existingSubscriberCheck.data
          ? existingSubscriberCheck.data.newsletterSubscribers.length
          : -1
        console.log("subscriberLength", subscriberLength)
        if (subscriberLength === 0) {
          postSubscriber()
        } else {
          setSubscribeSucess("")
          setSubscribeError("EmailID already registered")
        }
      } catch (e) {
        console.log("create user error", e.message)
      }
    } else {
      setSubscribeSucess("")
      setSubscribeError("Enter a valid mail address")
    }
  }

  const postSubscriber = async () => {
    try {
      await createSubscriber({
        variables: {
          newsletterInput: {
            data: {
              Subscriber: subscriberMail,
            },
          },
        },
      }).then((createSubscriberResponse) => {
        console.log("reponse", createSubscriberResponse.data)
        if (createSubscriberResponse.data.length !== 0) {
          const result = addToMailchimp(subscriberMail, {
            "group[223596][1]": true,
            "group[223596][2]": true,
            "group[223596][4]": true,
          })
          console.log("result", result)
          setSubscriberEmail("")
          setSubscribeError("")
          setSubscribeSucess("Subscribed successfully...!")
        }
      })
    } catch (e) {
      setSubscribeSucess("")
      console.log("post subcriber error", e.message)
    }
  }
  const calendlyAction = () => {
    return (
      <div>
        {openPopupWidget({
          url: "https://calendly.com/dimensionsfrance/bonjour",
          prefill: null,
          pageSettings: null,
          utm: null,
        })}
      </div>
    )
  }

  const { allStrapiHomePage } = data
  const seoTitle =
    allStrapiHomePage !== null ? allStrapiHomePage.edges[0].node.title : ""
  const seoMetaDescription =
    allStrapiHomePage !== null
      ? allStrapiHomePage.edges[0].node.metaDescription
      : ""
  const seoImage =
    allStrapiHomePage !== null
      ? allStrapiHomePage.edges[0].node.metaImage
        ? allStrapiHomePage.edges[0].node.metaImage.url
        : ""
      : ""

  if (homepageContent.loading)
    return (
      <>
        <SEO
          title={seoTitle}
          description={seoMetaDescription}
          image={seoImage}
        />
        <div className={"centered"}>
          <BounceIndicator size={25} color={"#0055a4"} />
        </div>
      </>
    )
  return (
    <>
      <SEO title={seoTitle} description={seoMetaDescription} image={seoImage} />
      <Layout setTitle={"Home"}>
        {homepageContent.data ? (
          <>
            <Login
              open={open}
              setOpen={setOpen}
              login={login}
              setLogin={setLogin}
              setUsername={setUsername}
              setUserID={setUserID}
              forgotPassword={forgotPassword}
              setForgotPassword={setForgotPassword}
              errorMessage ={errorMessage}
              setErrorMessage ={setErrorMessage}
              successMessage ={successMessage}
              setSuccessMessage ={setSuccessMessage}
              phoneError ={phoneError}
              setphoneError ={setphoneError}
            />

            <div className="main-content">
              {/* <!-- banner ---> */}
              <section
                className="home-banner-content desktop"
                style={{
                  backgroundImage: `url(${bannerImage})`,
                  backgroundAttachment: "fixed",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              >
                <div className="container">
                  <div className="banner-section">
                    <div className="left">
                      <h1>
                        {homepageContent.data.homePage.bannerTitle
                          ? parse(homepageContent.data.homePage.bannerTitle)
                          : ""}
                      </h1>
                      <p>
                        {homepageContent.data.homePage.bannerDescription
                          ? parse(
                            homepageContent.data.homePage.bannerDescription
                          )
                          : ""}
                      </p>
                      {(localStorage.getItem("userName") === null &&
                        userID === null) ||
                        (localStorage.getItem("userName") === "" &&
                          userID === "") ? (
                        <button
                          className="talk"
                        >
                          <Link to= {`${process.env.FILLOUT_ENQUIRY_FORM}`} className="text-dark">
                            {homepageContent.data.homePage.bannerButtonName
                              ? homepageContent.data.homePage.bannerButtonName
                              : ""}


                          </Link>

                        </button>
                      ) : null}
                    </div>
                    {/* <div className="right">
                      <img
                        src={homepageContent.data.homePage.bannerImage ?
                          process.env.BACKEND_URL + homepageContent.data.homePage.bannerImage.url : null}
                        alt={homepageContent.data.homePage.bannerImageAltName} />
                    </div> */}
                  </div>
                </div>
              </section>
              <section className="home-banner-content mobile">
                <div className="banner-image">
                  <img src={bannerImage} alt="bannerimg" />
                  <div className="banner-bg"></div>
                </div>
                <div className="container">
                  <div className="banner-section">
                    <div className="left">
                      <h1>
                        {homepageContent.data.homePage.bannerTitle
                          ? parse(homepageContent.data.homePage.bannerTitle)
                          : ""}
                      </h1>
                      <p>
                        {homepageContent.data.homePage.bannerDescription
                          ? parse(
                            homepageContent.data.homePage.bannerDescription
                          )
                          : ""}
                      </p>
                      {(localStorage.getItem("userName") === null &&
                        userID === null) ||
                        (localStorage.getItem("userName") === "" &&
                          userID === "") ? (
                        <div className="talk">
                        <button
                          className="text-dark"
                          onClick={(e) => {
                            e.preventDefault()
                            setOpen(true)
                            setLogin(true)
                            setSuccessMessage("")
                            setErrorMessage("")
                            setphoneError("")
                            localStorage.removeItem("redirect")
                            return false
                          }}
                        >
                          {homepageContent.data.homePage.bannerButtonName
                            ? homepageContent.data.homePage.bannerButtonName
                            : ""}
                        </button>
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="right">
                  <img 
                    src={homepageContent.data.homePage.bannerImage?
                            process.env.BACKEND_URL+homepageContent.data.homePage.bannerImage.url:null} 
                    alt={homepageContent.data.homePage.bannerImageAltName} />
                </div> */}
                  </div>
                </div>
              </section>
              {/* <!-- /banner ---> */}
              <div className="main-home">
                {/* <!-- unwrap france ---> */}
                <div className="unwrap-slider pt-100">
                  <div className="container">
                    <div className="m-title">
                      {homepageContent.data.homePage.unwrapSliderMainTitle
                        ? homepageContent.data.homePage.unwrapSliderMainTitle
                        : ""}
                    </div>
                    <div className="row align-items-center">
                      <div
                        id="main-banner"
                        className="main-banner image-carousel-two"
                      >
                        <OwlCarousel
                          className="owl-theme"
                          autoplay={1}
                          loop
                          margin={1}
                          nav
                          items={1}
                          autoplayTimeout={15000}
                        >
                          {homepageContent.data.homePage.unwrapContent.map(
                            (content, index) => (
                              <div className="item" key={content.id}>
                                <div className="container">
                                  <div className="carousel-single row">
                                    <div className="col-md-6">
                                      <h2 className="display2 blue">
                                        {content.title}
                                      </h2>
                                      <div className="content">
                                        {content.unwrapSubContent.map(
                                          (subContent, index) => (
                                            <div
                                              className="list"
                                              key={content.id}
                                            >
                                              <div className="icon">
                                                <img
                                                  src={
                                                    subContent.thumbnailIcon
                                                      ? process.env
                                                        .BACKEND_URL +
                                                      subContent.thumbnailIcon
                                                        .url
                                                      : null
                                                  }
                                                  alt={
                                                    subContent.thumbnailIconAltName
                                                  }
                                                />
                                              </div>
                                              <div className="content-area">
                                                <h4 className="subtitle">
                                                  {subContent.title}
                                                </h4>
                                                <p>{subContent.description}</p>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-6 text-center">
                                      <div className="shape-wrapper">
                                        {/* <div class="shape-wrapper img-shape-one">
                                   {content.sliderImage?
                                      <>
                                        <div class="left">
                                            <div class="y-bg"></div>
                                            {content.sliderImage.length >0?
                                              <img src={content.sliderImage[0]? process.env.BACKEND_URL+content.sliderImage[0].url:null} alt={content.sliderImageAltName} />
                                              : <img src={unwarp_placeholder_1} alt={content.sliderImageAltName} />}
                                           
                                            </div>
                                        <div class="right">
                                          {content.sliderImage.length >1?
                                            <img src={content.sliderImage[1]? process.env.BACKEND_URL+content.sliderImage[1].url:null} alt={content.sliderImageAltName} />
                                            :<img src={unwarp_placeholder} alt={content.sliderImageAltName} />}
                                            <div class="yl-bg"></div>
                                        </div>
                                      </>
                                      :null} 
                                  </div> */}
                                        <img
                                          src={
                                            content.sliderImageValue
                                              ? process.env.BACKEND_URL +
                                              content.sliderImageValue.url
                                              : null
                                          }
                                          alt={content.sliderImageAltName}
                                        />
                                        {/* <div className="left">
                                        <div className="y-bg"></div>
                                        <img src={l2} alt="" />
                                      </div> */}
                                        {/* <div className="right">
                                        <img src={l1} alt="" />
                                        <div className="yl-bg"></div>
                                      </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /unwrap france ---> */}

                {/* <!-- Nothing to change --> */}
                <div className="unwrap-slider nothing-change">
                  <div className="container">
                    <div className="m-title">
                      {homepageContent.data.homePage.leaveNothingSliderMainTitle
                        ? homepageContent.data.homePage
                          .leaveNothingSliderMainTitle
                        : ""}
                    </div>
                    <div className="row align-items-center">
                      <div
                        id="nothing-change"
                        className="main-banner image-carousel-two"
                      >
                        <OwlCarousel
                          className="owl-theme"
                          autoplay={1}
                          loop
                          margin={1}
                          nav
                          items={1}
                          autoplayTimeout={15000}
                        >
                          {homepageContent.data.homePage.leaveNothingContent.map(
                            (content, index) => (
                              <div className="item" key={content.id}>
                                <div className="container">
                                  <div className="carousel-single row">
                                    <div className="col-md-6 text-center">
                                      <div className="shape-wrapper">
                                        <img
                                          src={
                                            content.image
                                              ? process.env.BACKEND_URL +
                                              content.image.url
                                              : ""
                                          }
                                          alt={content.imageAltName}
                                        />

                                        {/* <div className="left">
                                  <img src="../assets/images/l1.png" alt="" />
                              </div>
                              <div className="right">
                                  <img src="../assets/images/l2.png" alt="" />
                                  <div className="yl-bg"></div>
                              </div> */}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <h2 className="display2 blue">
                                        {content.title}
                                      </h2>
                                      <div className="content">
                                        <div className="list">
                                          <div className="content-area">
                                            <ReactMarkdown
                                              source={content.description}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Nothing to change --> */}

                {/* <!-- alternate --> */}
                <div className="unwrap-slider alternate">
                  <div className="top-left-bg"></div>
                  <div className="container">
                    <div className="m-title">
                      {homepageContent.data.homePage.alternateDimensionMainTitle
                        ? homepageContent.data.homePage
                          .alternateDimensionMainTitle
                        : ""}
                    </div>
                    <div className="content-section">
                      <div className="row">
                        <div className="col-md-6">
                          {homepageContent.data.homePage.alternateDimensionContent.map(
                            (content, index) => (
                              <>
                                {index === 0 || index === 1 ? (
                                  <div className="dim-list" key={content.id}>
                                    <div className="img">
                                      <img
                                        src={
                                          content.thumbnailIcon
                                            ? process.env.BACKEND_URL +
                                            content.thumbnailIcon.url
                                            : null
                                        }
                                        alt={content.thumbnailIconAltName}
                                      />
                                    </div>
                                    <div className="text">
                                      <div className="title">
                                        {content.title}
                                      </div>
                                      <p>
                                        <ReactMarkdown
                                          source={content.description}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            )
                          )}
                        </div>
                        <div className="col-md-6 mv-down">
                          {homepageContent.data.homePage.alternateDimensionContent.map(
                            (content, index) => (
                              <>
                                {index === 2 ? (
                                  <div className="dim-list" key={content.id}>
                                    <div className="img">
                                      <img
                                        src={
                                          content.thumbnailIcon
                                            ? process.env.BACKEND_URL +
                                            content.thumbnailIcon.url
                                            : null
                                        }
                                        alt={content.thumbnailIconAltName}
                                      />
                                    </div>
                                    <div className="text">
                                      <div className="title">
                                        {content.title}
                                      </div>
                                      <p>
                                        <ReactMarkdown
                                          source={content.description}
                                        />
                                      </p>
                                    </div>
                                  </div>
                                ) : index === 3 ? (
                                  <div className="dim-list" key={content.id}>
                                    <div className="img">
                                      <img
                                        src={
                                          content.thumbnailIcon
                                            ? process.env.BACKEND_URL +
                                            content.thumbnailIcon.url
                                            : null
                                        }
                                        alt={content.thumbnailIconAltName}
                                      />
                                    </div>
                                    <div className="text">
                                      <div className="title">
                                        {content.title}
                                      </div>
                                      <p>
                                        <ReactMarkdown
                                          source={content.description}
                                        />
                                      </p>
                                      {/*<Link to={"/contact-us"} style={{textDecoration:"none"}} >
                                  <div className="link-btn">
                                  <div className="talk">Talk to us</div>
                                  </div>
                                </Link>*/}
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-right-bg"></div>
                </div>
                {/* <!-- /alternate --> */}
                {/* <!-- repeat grid --> */}
                {homepageContent.data.homePage.storiesContent ? (
                  <div className="unwrap-slider quotes">
                    <div className="repeat-bg">
                      <div className="container">
                        <div className="m-title">
                          {homepageContent.data.homePage.storiesContent
                            .mainTitle
                            ? homepageContent.data.homePage.storiesContent
                              .mainTitle
                            : ""}
                        </div>
                        <div className="subtitle blue">
                          {homepageContent.data.homePage.storiesContent.subTitle
                            ? homepageContent.data.homePage.storiesContent
                              .subTitle
                            : ""}
                        </div>
                        <div className="quote-img">
                          <img src={quote} alt="" />
                        </div>
                        <div className="text">
                          {homepageContent.data.homePage.storiesContent
                            .quoteContent
                            ? parse(
                              homepageContent.data.homePage.storiesContent
                                .quoteContent
                            )
                            : ""}
                        </div>
                        {homepageContent.data.homePage.reviewContent ? (
                          <div
                            id="quotes"
                            className="main-banner image-carousel-two"
                          >
                            <OwlCarousel
                              className="owl-theme"
                              autoplay={1}
                              loop
                              margin={1}
                              nav
                              items={1}
                              dots={false}
                            >
                              {homepageContent.data.homePage.reviewContent.map(
                                (content, index) => (
                                  <div className="item" key={content.id}>
                                    <div className="container">
                                      <div className="carousel-single row">
                                        <div className="context">
                                          <ReactMarkdown
                                            source={content.comments}
                                          />
                                        </div>
                                        <div className="image">
                                          <img
                                            src={
                                              content.profileImage
                                                ? process.env.BACKEND_URL +
                                                content.profileImage.url
                                                : default_user
                                            }
                                            alt={content.profileImageAltName}
                                            style={{ borderRadius: "5px" }}
                                          />
                                        </div>
                                        <div className="user-detail">
                                          <p>{content.profileName}</p>
                                          <h5>{content.designationName}</h5>
                                          {content.socialMediaContent.map(
                                            (content, index) => (
                                              <Link
                                                to={
                                                  content.socialIconURL
                                                    ? content.socialIconURL
                                                    : "/"
                                                }
                                                target="_blank"
                                              >
                                                <img
                                                  src={
                                                    content.socialMediaIcon
                                                      ? process.env
                                                        .BACKEND_URL +
                                                      content.socialMediaIcon
                                                        .url
                                                      : null
                                                  }
                                                  alt=""
                                                />
                                              </Link>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </OwlCarousel>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* <!-- /repeat grid --> */}
                {/* <!-- our blogs --> */}
                {homepageContent.data.homePage.ourBlogs ? (
                  <div className="unwrap-slider our-blogs blog-page">
                    <div className="container trending">
                      <div className="m-title">Our blogs</div>
                      <div className="trends row-top">
                        {homepageContent.data.homePage.ourBlogs.map(
                          (content, index) => (
                            <div className="blog-grid" key={content.id}>
                              <div className="deal-list right">
                                <div className="image">
                                  {content.image.length >= 1 ? (
                                    <img
                                      src={
                                        content.image[0].url
                                          ? process.env.BACKEND_URL +
                                          content.image[0].url
                                          : trends_placeholder
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <img src={trends_placeholder} alt="" />
                                  )}
                                  <div className="icons">
                                    <div class="overlay">
                                      <div class="heart">
                                        {!handleFavoriteCheck(content.id) ? (
                                          <i
                                            class="far fa-heart"
                                            onClick={() => {
                                              favoriteClick(content.id, content)
                                            }}
                                          ></i>
                                        ) : (
                                          <i
                                            class="fas fa-heart"
                                            style={{
                                              color: "#ff0000",
                                            }}
                                            onClick={() => {
                                              favoriteUnClick(
                                                content.id,
                                                content
                                              )
                                            }}
                                          ></i>
                                        )}
                                        {content.favoriteCount}
                                      </div>
                                      <div class="tag">
                                        {!handleBookmarkCheck(content.id) ? (
                                          <i
                                            class="far fa-bookmark"
                                            onClick={() => {
                                              bookmarkClick(content.id, content)
                                            }}
                                          ></i>
                                        ) : (
                                          <i
                                            class="fas fa-bookmark"
                                            onClick={() => {
                                              bookmarkUnClick(
                                                content.id,
                                                content
                                              )
                                            }}
                                          ></i>
                                        )}
                                        {content.wishlistCount}
                                      </div>
                                      {/* <div class="share">
                                <img src={shareimg} alt="" />
                              </div> */}
                                      <div class="share">
                                        <img
                                          src={shareimg}
                                          alt=""
                                          className="share-img"
                                          onClick={() => {
                                            !showShareOption
                                              ? setShowShareOption(true)
                                              : setShowShareOption(false)
                                          }}
                                        />
                                        {showShareOption ? (
                                          <div class="social">
                                            <ul>
                                              <li>
                                                <FacebookShareButton
                                                  quote={
                                                    seoMetaDescription || seoTitle
                                                  }
                                                  url={
                                                    process.env.GATSBY_URL +
                                                    `/blog/${content.url}`
                                                  }
                                                  hashtag="#dimensionsFrance"
                                                >
                                                  <img
                                                    src={fb_icon}
                                                    alt="facebook"
                                                    style={{
                                                      height: "20px",
                                                      width: "10px",
                                                    }}
                                                  />
                                                </FacebookShareButton>
                                              </li>
                                              <li>
                                                <LinkedinShareButton
                                                  title={"Dimensions France"}
                                                  summary={
                                                    seoMetaDescription || seoTitle
                                                  }
                                                  url={
                                                    process.env.GATSBY_URL +
                                                    `/blog/${content.url}/`
                                                  }
                                                  source={
                                                    process.env.GATSBY_URL +
                                                    `/blog/${content.url}/`
                                                  }
                                                  hashtag="#dimensionsFrance"
                                                >
                                                  <img
                                                    src={linkedin_icon}
                                                    alt="linkedin"
                                                  />
                                                </LinkedinShareButton>
                                              </li>
                                              <li>
                                                <WhatsappShareButton
                                                  title={
                                                    seoMetaDescription || seoTitle
                                                  }
                                                  url={
                                                    process.env.GATSBY_URL +
                                                    `/blog/${content.url}`
                                                  }
                                                  hashtag="#dimensionsFrance"
                                                >
                                                  <img
                                                    src={whatsapp_icon}
                                                    alt="whatsapp"
                                                  />
                                                </WhatsappShareButton>
                                              </li>
                                              <li>
                                                <TwitterShareButton
                                                  title={
                                                    seoMetaDescription || seoTitle
                                                  }
                                                  url={
                                                    process.env.GATSBY_URL +
                                                    `/blog/${content.url}`
                                                  }
                                                  hashtag="#dimensionsFrance"
                                                >
                                                  <img
                                                    src={twitter_icon}
                                                    alt="twitter"
                                                  />
                                                </TwitterShareButton>
                                              </li>
                                            </ul>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="details">
                                  <h6>
                                    <img
                                      style={{ width: 14 }}
                                      src={
                                        content.topic.thumbnail
                                          ? process.env.BACKEND_URL +
                                          content.topic.thumbnail.url
                                          : ""
                                      }
                                      alt={content.altThumbnailName}
                                    />
                                    <span>{content.topic.title}</span>
                                  </h6>
                                  <div className="dtitle editicon">
                                    <Link
                                      // to={`/blog-detail`}
                                      // state={{ id: content.id }}
                                      to={`/blog/${content.url}/`}
                                      style={{
                                        textDecoration: "none",
                                      }}
                                    >
                                      <LinesEllipsis
                                        text={content.title}
                                        maxLine="3"
                                        ellipsis="..."
                                        trimRight
                                        basedOn="letters"
                                      />
                                    </Link>
                                  </div>
                                </div>
                                <div className="s-icon">
                                  <div className="image">
                                    <img
                                      src={
                                        content.author.profileImage
                                          ? process.env.BACKEND_URL +
                                          content.author.profileImage.url
                                          : default_profile_img
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="right">
                                    <div className="name">
                                      {content.author.firstName}{" "}
                                      {content.author.lastName}
                                    </div>
                                    <div className="date">
                                      {content.blogDate ? (
                                        <>
                                          {moment(content.blogDate).format(
                                            "MMMM DD"
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}{" "}
                                      |{" "}
                                      {content.ReadingTimeInMinutes
                                        ? content.ReadingTimeInMinutes
                                        : 0}{" "}
                                      Min Read
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                        {homepageNewsletterContent.data ? (
                          <div className="blog-grid newsletter">
                            <div className="deal-list right news">
                              <div className="image">
                                <img
                                  src={
                                    homepageNewsletterContent.data
                                      .homepageNewsletter.thumbnailImage
                                      ? process.env.BACKEND_URL +
                                      homepageNewsletterContent.data
                                        .homepageNewsletter.thumbnailImage.url
                                      : null
                                  }
                                  alt={
                                    homepageNewsletterContent.data
                                      .homepageNewsletter.thumbnailImageAltName
                                  }
                                />
                              </div>
                              <div className="content">
                                <h3 className="white">
                                  {homepageNewsletterContent.data
                                    .homepageNewsletter.title
                                    ? homepageNewsletterContent.data
                                      .homepageNewsletter.title
                                    : ""}
                                </h3>
                                <p>
                                  {homepageNewsletterContent.data
                                    .homepageNewsletter.description
                                    ? homepageNewsletterContent.data
                                      .homepageNewsletter.description
                                    : ""}
                                </p>
                                <div>
                                  <input
                                    type="email"
                                    id="email"
                                    className="emailid"
                                    name="email"
                                    value={subscriberMail}
                                    placeholder="Email Address"
                                    onChange={(e) => {
                                      setSubscriberEmail(e.target.value)
                                    }}
                                  />
                                  <div className="success-message">
                                    {subscribeSucess}
                                  </div>
                                  <div className="error-message">
                                    {subscribeError}
                                  </div>
                                  {subscribeError === "" ? <br /> : null}
                                  <div className="talk newsletter">
                                  <button
                                    onClick={() => handleSubmit()}
                                    className="newsletterbutton"
                                  >
                                    {homepageNewsletterContent.data
                                      .homepageNewsletter.buttonName
                                      ? homepageNewsletterContent.data
                                        .homepageNewsletter.buttonName
                                      : ""}
                                  </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* <!-- /our blogs --> */}
                {/* <!-- our partner --> */}
                {homepageContent.data.homePage.partnerContent ? (
                  <div className="unwrap-slider our-partner">
                    <div className="container">
                      <div className="m-title">
                        {homepageContent.data.homePage.partnerContent.mainTitle
                          ? homepageContent.data.homePage.partnerContent
                            .mainTitle
                          : ""}
                      </div>
                      <div className="subtitle blue">
                        {homepageContent.data.homePage.partnerContent.subTitle
                          ? homepageContent.data.homePage.partnerContent
                            .subTitle
                          : ""}
                      </div>
                      <div className="row">
                        {homepageContent.data.homePage.partnerContent.partnerImage.map(
                          (content, index) => (
                            <div className="col-sm-3 mb-15" key={content.id}>
                              <div className="logo-img">
                                <img
                                  src={
                                    content.logoImage
                                      ? process.env.BACKEND_URL +
                                      content.logoImage.url
                                      : ""
                                  }
                                  alt={content.logoAltName}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* <!-- /our blogs --> */}
                {/* <!---- Consultation --------> */}
                <section className="ptop">
                {showAppoinment && <AppoinmentBooking isOpen={showAppoinment} handleClose={()=>setshowAppoinment(false)} />}
                  <div className="consultation pb100">
                    <div className="container">
                      {consultationBlock.data ? renderConsultationBlock() : ""}
                    </div>
                  </div>
                </section>
                {/* <!---- /Consultation --------> */}
              </div>
            </div>
          </>
        ) : null}
      </Layout>
    </>
  )
}
export default Home

export const query = graphql`
  query {
    allStrapiHomePage {
      edges {
        node {
          id
          title
          metaDescription
          metaImage {
            url
          }
        }
      }
    }
  }
`
